import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Redirect, withRouter } from 'react-router';
const AuthenticatedRoute = ({component: Component, ...rest}) => ( 
  <Route {...rest} render={props => localStorage.getItem("accessToken")!=null ? (
    
      <Component {...props}/>
  ): 
   (
      <Redirect to={{pathname: "/auth/signin-1", state: {from: props.location}}}/>
  )
  } />
);

export default withRouter(AuthenticatedRoute);

{/* <Route
     path={props.route.path}
     exact={props.route.exact}
     name={props.route.name}
     render={props => localStorage.getItem("accessToken") ? (
      <props.route.component {...props} />):(
        <Redirect to={{pathname: "/auth/signin-1", state: {from: props.location}}}/>)
        }
     />      */}